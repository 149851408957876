section.orangeRow {
    background: $primary url(../images/orange-bars-too-tall.jpg) 0 100% repeat-x;
    text-align: center;
    @include font-size(18);
    padding: 50px 0;
    color: $secondary;

    @include media-breakpoint-up(md) {
        @include font-size(21);

        padding: 60px 0 50px;
    }

    @include media-breakpoint-up(lg) {
        @include font-size(24);
    }

    h2 {
        @include font-size(50);
        font-weight: normal;
        font-family: $font-family-serif;
        margin: 0 0 40px;
        color: $secondary;
        line-height: 1;

        @include media-breakpoint-up(md) {
            @include font-size(70);
        }

        @include media-breakpoint-up(lg) {
            @include font-size(81);
        }
    }

    p {
        margin: 0 0 4rem;

        &:last-child {
            margin: 0;
        }
    }
}