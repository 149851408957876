section.fullCta {
    background-size: cover;
    background-position: 0 50%;
    background-repeat: no-repeat;
    padding: 60px 15px 70px;

    @include media-breakpoint-up(lg) {
        padding: 75px 0;
    }

    .cta-caption {
        background: #fff;
        padding: 60px 32px;
        text-align: center;
        @include font-size(16);

        @include media-breakpoint-up(lg) {
            padding: 85px 85px 95px;
            @include font-size(18);
            margin-right: 15px;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            left: 10px;
            right: 10px;
            bottom: 10px;
            border: 1px solid $primary;
            pointer-events: none;

            @include media-breakpoint-up(lg) {
                top: 30px;
                left: 30px;
                right: 30px;
                bottom: 30px;
            }
        }

        h2 {
            @include font-size(24);
            font-weight: 900;

            @include media-breakpoint-up(lg) {
                @include font-size(34);
            }
        }

        .subtitle {
            color: $secondary;
            @include font-size(18);
            border-bottom: 1px solid $gray;
            position: relative;
            padding: 0 0 25px;
            margin: 0 0 40px;

            @include media-breakpoint-up(lg) {
                @include font-size(24);
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: -1px;
                left: 50%;
                transform: translate(-50%, 0);
                height: 8px;
                width: 60px;
                background: $primary;
                pointer-events: none;
            }
        }

        p {
            line-height: 1.8;

            &:last-child {
                margin-top: 3rem;
            }
        }
    }
}