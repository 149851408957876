.officeBox-wrapper {

    @include media-breakpoint-up(md) {
        padding-top: 50px;
    }
}

.officeBox {
    margin: 0 0 50px;
    @include font-size(16);

    @include media-breakpoint-up(md) {
        padding-right: 35px;
        margin: 0 0 50px !important;
    }

    h3 {
        @include font-size(18);
    }

    a {
        font-weight: 700;

        &::before {
            content: "";
            display: inline-block;
            font-family: $icon-font;
            margin: 0 13px 0 0;
            font-weight: 400;
            color: $accent1;
        }

        &.phone {

            &::before {
                content: "\f095";
            }
        }
    }
}