.styleguide {
	padding: 50px 0;

	code {
		background: $gray-lightest;
		padding: 9px 15px;
		color: $gray;
		display: inline-block;
	}

	hr {
		border: none;
		border-top: 1px solid $gray;
		margin: 25px 0;
	}
}

.styleguide-item {
	padding: 0 0 25px;
}

.bg-orange-stripes {
	background: $primary url(../images/orange-bars-tall.jpg) 0 100% repeat-x;
}

.bg-lightest-gray {
	background: $gray-lightest;
}

.bg-dark-blue {
	background: $secondary;
}

.script-text {
	font-family: $font-family-serif;
	font-weight: normal;
}