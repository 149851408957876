.viewOptions {
    @include font-size(10);
    font-weight: 700;
    text-transform: uppercase;
    display: none;

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 25px;
    }
    
    .total {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
            @include font-size(24);
            text-transform: none;
            font-weight: normal;
        }

        span {
            font-weight: 900;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-end;
    }

    li {
        margin: 0 21px 0 0;
        cursor: pointer;

        &:hover {
            color: $primary;
        }

        &::before {
            content: "";
            display: inline-block;
            font-family: $icon-font;
            font-weight: 700;
            margin: 0 7px 0 0;
        }

        &.grid {

            &::before {
                content: "\f00a";
            }
        }

        &.list {

            &::before {
                content: "\f03a";
            }
        }

        &.map {

            &::before {
                content: "\f3c5";
            }
        }

        a {
            color: $gray-darkest;
        }
    }
}