.sliderContainer {
	margin: 0 0 75px;
	position: relative;
	white-space: nowrap;

	@include media-breakpoint-up(md) {
		margin: 0 0 115px;
	}
}

.sliderContainer-inner {
	//width: 100%;
	overflow: hidden;
	white-space: nowrap;
	//position: absolute;
	//top: 0;
	//left: 0;
	height: 100%;
	width: 100%;
	font-size: 0;
	
	.slide {
		display: inline-block;
		width: 100%;
		position: relative;
		white-space: normal;

		&::after {
			content: "";
			display: block;
			padding: 0 0 60%;
		}

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			transform: translate(-50%, -50%);
		}
	}
}

.slider-orange {
	display: none;

	@include media-breakpoint-up(lg) {
		display: block;
		position: absolute;
		top: 0;
		left: -15px;
		bottom: 0;
		width: 15px;
		background: $primary url(../images/orange-bars-too-tall.jpg) 0 100% repeat-x;
	}
}

.sliderContainer-nav {
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, 0);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 13px 0;
	font-weight: bold;
}

.sliderCount {
	padding: 0 21px;
}

.sliderPrev {
	cursor: pointer;

	&.off {
		opacity: 0.5;
		cursor: default;
		pointer-events: none;
	}

	&:hover {
		color: $primary;
	}

	&::before {
		content: "\f053";
		font-family: $icon-font;
	}
}

.sliderNext {
	cursor: pointer;

	&.off {
		opacity: 0.5;
		cursor: default;
		pointer-events: none;
	}

	&:hover {
		color: $primary;
	}

	&::before {
		content: "\f054";
		font-family: $icon-font;
	}
}