section.halfCta {
    padding: 45px 0 0;

    @include media-breakpoint-up(md) {
        padding: 0;
        background: $gray-lightest;
    }

    .container {

        @include media-breakpoint-up(md) {
            padding: 0;
            max-width: 1700px;
        }
    }
}

.halfCta-thumb {
    display: none;
    position: relative;

    @include media-breakpoint-up(md) {
        display: block;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        overflow: hidden;
    }

    &:nth-child(2) {

        &::after {
            right: auto;
            left: 0;
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%) rotate(45deg);
        border: 10px solid #fff;
        width: 90px;
        height: 90px;
    }
}

.halfCta-content {
    text-align: center;
    position: relative;
    padding: 29px 25px 0px;
    @include font-size(16);
    line-height: 1.6;
    background: #fff;

    @include media-breakpoint-up(md) {
        text-align: left;
        padding: 100px 30px 60px;
    }

    @include media-breakpoint-up(lg) {
        @include font-size(18);
        padding: 100px 60px 60px;
    }

    @include media-breakpoint-up(xl) {
        padding: 7.5vw 8vw 6vw;
    }

    &:nth-child(4n+3) {

        @include media-breakpoint-up(md) {
            text-align: right;
        }

        &::before {

            @include media-breakpoint-up(md) {
                left: auto;
                right: 30px;
            }

            @include media-breakpoint-up(lg) {
                right: 60px;
            }
            
            @include media-breakpoint-up(xl) {
                right: 8vw;
            }
        }
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        height: 10px;
        width: 120px;
        background: $accent1;

        @include media-breakpoint-up(md) {
            left: 30px;
            top: 60px;
            transform: translate(0, 0);
        }

        @include media-breakpoint-up(lg) {
            left: 60px;
        }

        @include media-breakpoint-up(xl) {
            left: 8vw;
            top: calc(7.5vw - 35px);
        }
    }

    h2 {
        @include font-size(24);
        font-weight: 900;
        color: $secondary;
        margin: 0 0 15px;

        @include media-breakpoint-up(lg) {
            @include font-size(34);
        }
    }

    p {

        &:last-child {
            margin-top: 31px;
            
            @include media-breakpoint-up(lg) {
                margin-top: 50px;
            }
        }
    }

    .btn {
        display: block;
        width: 100%;
        max-width: 260px;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            display: inline-block;
            margin: 0;
            max-width: 100%;
            width: auto;
        }
    }
}