@import 'https://fonts.googleapis.com/css?family=Lato:400,700,900';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/brands';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/regular';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/solid';



@import '_helpers/_mixins';
@import '_helpers/_bs-variables';
@import '_helpers/_app-variables';
@import '_helpers/_fonts';

@import '../../node_modules/bootstrap/scss/bootstrap-reboot';
@import '../../node_modules/bootstrap/scss/bootstrap-grid';
@import '../../node_modules/bootstrap/scss/utilities/screenreaders';
@import '../../node_modules/bootstrap/scss/utilities/text';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/modal';

@import '_includes/_mixins';

@import '_base/_base';
@import '_base/_skippy';

@import '_layout/_header';
@import '_layout/_footer';
@import '_layout/_top-bar';
@import '_layout/_main-nav';
@import '_layout/_main-menu';
@import '_components/_search-row';
@import '_components/_buttons';
@import '_components/_forms';
@import '_components/_full-cta';
@import '_components/_half-cta';
@import '_components/_what-matters';
@import '_components/_sub-banner';
@import '_components/_crumbs';
@import '_components/_orange-row';
@import '_components/_cta-box-row';
@import '_components/_social-row';
@import '_components/_home-hero';
@import '_components/_icon-cta-row';
@import '_components/_general-body';
@import '_components/_image-caption';
@import '_components/_modal';
@import '_components/_logo-box';
@import '_components/_search-results';
@import '_components/_pagination';
@import '_components/_hover-box';
@import '_components/_resource-box';
@import '_components/_area-properties';
@import '_components/_view-options';
@import '_components/_property-search-row';
@import '_components/_contact-search';
@import '_components/_office-box';
@import '_components/_detail-hero';
@import '_components/_detail-tabs';
@import '_components/_detail-downloads';
@import '_components/_routing-box-wrapper';
@import '_components/_sort-row';
//@import '_components/_lightbox';
@import '_components/_slider';
@import '_components/_leadership-detail';
@import '_components/_image-gallery';
@import '_helpers/_styleguide';

html,
body {
    position: relative;
}

img {
    max-width: 100%;
}

.btn {
    @include font-size(16);
    font-weight: 700;
    border-radius: 0;
    padding: 9px 31px;

    @include media-breakpoint-up(md) {
        @include font-size(18);
    }
}