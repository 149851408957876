ul.sortRow {
    list-style: none;
    margin: 0;
    padding: 0;
    display: none;
    background: $gray-lightest;

    @include media-breakpoint-up(md) {
        margin: 0 0 25px;
        @include font-size(16);
        padding: 0 15px;
        position: relative;
    }

    &::before {
        content: "";
        width: 15px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 100%;
        width: 15px;
        background: $gray-lightest;
    }

    &::after {
        content: "";
        width: 15px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        width: 15px;
        background: $gray-lightest;
    }

    &.on {

        @include media-breakpoint-up(md) {
            display: flex;
        }
    }

    li {
        position: relative;
        padding: 15px 0;

        &.property {
            flex-grow: 1;
        }

        &.availability,
        &.tenants {
            min-width: 23%;
            max-width: 23%;
        }

        &.gla,
        &.contact {
            min-width: 12%;
            max-width: 12%;
        }

        &.sortable {

            &.up {

                a {
                    
                    &::before {
                        content: "\f0d8";
                    }
                }
            }

            &.down {
                
                a {

                    &::before {
                        content: "\f0d7";
                    }
                }
            }

            a {

                &::before {
                    content: "\f0dc";
                    font-weight: 700;
                    font-family: $icon-font;
                    display: inline-block;
                    margin: 0 5px 0 0;
                    pointer-events: none;
                }
            }
        }

        &:hover {
            //background: $gray-lighter;
            color: $primary;
        }

        a {
            color: $gray-darkest;

            &:hover {
                color: $gray-darkest;
            }
        }
    }
}