section.crumbs {
    padding: 13px 0;
    color: $gray-darkest;
    @include font-size(12);
    font-weight: 700;

    ol,
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        display: inline;
    }

    a {
        color: $gray-darkest;
    }
}