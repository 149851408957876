.hoverBox-wrapper {

    div.col-sm-6,
    div.col-md-3 {
        margin-bottom: 31px !important;
    }

    &.list .col-sm-6,
    &.list .col-md-4,
    &.list .col-lg-3 {

        @include media-breakpoint-up(md) {
            @include make-col(12);
            margin-bottom: 0 !important;
            padding: 0;

            &:nth-child(even) {
                background: $gray-lightest;
            }
        }
    }
}

a.hoverBox {
    box-shadow: 0 0 7px rgba(#000, 0.2);
    padding: 0;
    overflow: hidden;
    color: $secondary;
    @include font-size(14);
    font-weight: 700;
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
        text-decoration: none;
    }

    .grid & {

        &:hover {
            
            .hoverBox-hover {
                animation: hoverBox-bg 0.2s cubic-bezier(0.420, 0.000, 0.580, 1.000) forwards;
            }
        }
    }

    .list & {

        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: row;
            align-items: center;
            box-shadow: none;
            margin: 0;
            padding: 11px 21px;
        }

        @include media-breakpoint-up(lg) {
            align-items: stretch;
        }
    }

    .map & {
        display: none;
    }
}

.hoverBox-thumb {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &::after {
        content: "";
        display: block;
        padding: 0 0 80%;
    }

    .list & {
        width: 110px;
        min-width: 110px;
        align-self: center;
    }
}

.hoverBox-content {
    padding: 11px 21px;
    flex-grow: 1;
    border-bottom: 10px solid $primary;
    text-align: left;

    .list & {
        border: none;
        padding: 13px 23px;
        flex-grow: 1;
    }
    
    h2,
    .title {
        @include font-size(18);
        font-weight: 400;
        color: $secondary;
        margin: 0 0 4px;
    }

    p {
        margin: 0;
    }
}

.hoverBox-availability {

    .grid & {
        display: none;
    }

    .list & {
        display: block;
        min-width: 23%;
        max-width: 23%;
        color: $gray-darker;
        padding: 13px 25px 0 0;
        font-weight: 400;
    }
}

.hoverBox-gla {

    .grid & {
        display: none;
    }

    .list & {
        display: block;
        min-width: 12%;
        max-width: 12%;
        color: $gray-darker;
        padding: 13px 25px 0 0;
        font-weight: 400;
    }
}

.hoverBox-contact {

    .grid & {
        display: none;
    }

    .list & {
        display: block;
        min-width: 12%;
        max-width: 12%;
        color: $gray-darker;
        padding: 13px 25px 0 0;
        font-weight: 400;
    }
}

.hoverBox-tenants {

    .grid & {
        display: none;
    }

    .list & {
        display: block;
        min-width: 23%;
        max-width: 23%;
        color: $gray-darker;
        padding: 13px 25px 0 0;
        font-weight: 400;
    }
}

.hoverBox-hover {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0 25px;
    flex-wrap: wrap;
    color: #fff;
    text-align: left;
    @include font-size(16);
    flex-direction: column;
    opacity: 0;
    word-break: break-all;

    .name {
        @include font-size(18);
        font-weight: 700;
        min-width: 100%;
        max-width: 100%;
        word-break: break-all;
    }

    .action {
        min-width: 100%;
        max-width: 100%;
        word-break: break-all;
    }
}

@keyframes hoverBox-bg {
    from {
        background-color: transparent;
        top: 100%;
        opacity: 0;
    }
    to {
        background-color: rgba($secondary, 0.8);
        top: 0;
        opacity: 1;
    }
}

.hoverBox-action {
    padding: 25px 0 0;

    .map & {
        display: none;
    }
}

.mapList {

    .grid &,
    .list & {
        display: none;
    }

    .map & {
        display: block;
    }

    h2 {
        font-weight: 400;
        @include font-size(18);

        @include media-breakpoint-up(md) {
            @include font-size(24);
            margin: 0 0 31px;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        height: 365px;
        flex-wrap: wrap;
    }

    li {
        //width: 33.3333333%;
        margin: 0 0 7px;
    }

    a {
        color: $gray-darkest;
    }
}