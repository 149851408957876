.imageCaption {
    @include font-size(12);
    margin: 0 0 2.4rem;
    position: relative;

    &::after {
        content: "";
        
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            left: 0;
            width: 15px;
            height: 100%;
            background: $primary url(../images/orange-bars-too-tall.jpg) 0 100% repeat-x;
            z-index: 9;
        }

        @include media-breakpoint-up(lg) {
            left: -15px;
        }
    }

    img {
        margin: 0;
        border-left: 11px solid $primary;
        display: block;
        position: relative;
        width: 100%;
        height: auto;

        @include media-breakpoint-up(md) {
            border: none;
        }

        &::before {
            content: "";
            display: block;
            background: red;
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 20px;
            }
        }
    }

    p {
        background: #fff;
        position: relative;
        z-index: 10;
        margin: 0;
        padding: 15px 0 0;

        @include media-breakpoint-up(lg) {
            margin-left: -15px;
            padding-left: 15px;
        }
    }
}