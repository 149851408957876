.dropdownWrapper {
    position: relative;

    &::after {
        content: "\f078";
        font-family: $icon-font;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translate(0, -50%);
        pointer-events: none;
        font-weight: bold;
        @include font-size(18);
    }

    select {
        appearance: none;
        border: none;
        outline: 0 !important;
        width: 100%;
        height: 40px;
        padding: 0 30px 0 15px;
        border-radius: 0;
        @include font-size(16);
        color: $gray-darkest;
        font-weight: 700;
        background: #fff;
        border: 1px solid $gray-light;

        &::-ms-expand {
            display: none;
        }
    }
}

.contactForm-row {
    position: relative;
    margin: 0 0 20px;

    label {
        position: absolute;
        top: 0;
        left: 15px;
        transform: translate(0, -50%);
        background: #fff;
        @include font-size(12);
        font-weight: 700;
        text-transform: uppercase;
        padding: 0 9px;
    }

    input {
        appearance: none;
        border: none;

        &:disabled {
            background: $gray-lightest;
        }
    }

    input[type="text"],
    input[type="password"],
    input[type="phone"],
    input[type="number"] {
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        @include font-size(16);
        width: 100%;
        margin: 0;
        border: 1px solid $gray-light;
    }

    &.error {

        
    }

    textarea {
        border: none;
        appearance: none;
        width: 100%;
        padding: 15px;
        @include font-size(16);
        height: 200px;
        margin: 0;
        border: 1px solid $gray-lightest;
    }
}

.contactForm {

    input[type="radio"] {
        display: none;

        & + label {
            display: inline-block;
            padding: 0 0 0 25px;
            position: relative;
            margin: 0 21px 9px 0;
            font-weight: normal;
            @include font-size(16);
            text-transform: none;

            &::before {
                content: "\f111";
                font-family: $icon-font;
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                color: $secondary;
            }
        }

        &:checked + label {

            &::before {
                content: "\f111";
                font-weight: 700;
            }
        }
    }

    input[type="checkbox"] {
        display: none;

        & + label {
            display: inline-block;
            padding: 0 0 0 25px;
            position: relative;
            margin: 0 21px 9px 0;
            font-weight: normal;
            @include font-size(16);
            text-transform: none;

            &::before {
                content: "\f0c8";
                font-family: $icon-font;
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                color: $secondary;
            }
        }

        &:checked + label {

            &::before {
                content: "\f14a";
                font-weight: 700;
            }
        }
    }

    label {
        display: block;
        @include font-size(12);
        font-weight: 700;
        text-transform: uppercase;
        padding: 0;
    }
}

.contact-error {
    @include font-size(14);
    color: red;
    display: block;

    .contactForm-row + & {
        margin: -15px 0 20px;
    }
}