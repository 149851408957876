section.propertySearch {
    overflow: hidden;

    h1 {
        background: $gray-lightest;
        padding: 11px 0 15px;
        text-align: center;

        @include media-breakpoint-up(md) {
            padding: 21px 0 29px;
        }
    }

    .col-xl-10 {

        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }
}

.propertySearch-inner {

    @include media-breakpoint-up(lg) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.propertySearch-main {
    padding: 21px 0;

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    @include media-breakpoint-up(lg) {
        //justify-content: space-between;
    }

    input[type="text"] {
        height: 50px;
        line-height: 50px;
        border: 1px solid $gray;
        appearance: none;
        @include font-size(16);
        padding: 0 11px;

        @include placeholder {
            color: #333;
        }
    }
}

.propertySearch-input {

    label {
        display: block;
    }
    
    input {
        width: 100%;
        margin: 0 0 15px;

        @include media-breakpoint-up(sm) {
            width: 200px;
            margin: 0 35px 0 0;
        }

        @include media-breakpoint-up(lg) {
            width: 250px;
        }
    }
}

.propertySearch-sqFt {
    display: flex;
    align-items: center;
    justify-content: center;
    @include font-size(16);
    font-weight: 700;
    color: $secondary;
    margin: 0 0 25px;

    @include media-breakpoint-up(sm) {
        margin-top: 31px;
    }

    @include media-breakpoint-up(lg) {
        margin: 31px 25px 25px 0;
    }

    span {
        display: block;
        margin: 0 5px 0 0;
    }

    input {
        width: 80px;
        margin: 0 3px;

        @include media-breakpoint-up(sm) {
            margin: 0 15px 0 5px;
        }
    }
}

.propertySearch-actions {
    @include font-size(12);
    color: $secondary;
    text-align: center;

    @include media-breakpoint-up(sm) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type="checkbox"] {
        display: none;

        & + label {
            position: relative;
            display: inline-block;
            padding: 3px 0 3px 27px;

            &::before {
                content: "";
                display: inline-block;
                height: 20px;
                width: 20px;
                border: 2px solid $secondary;
                font-family: $icon-font;
                position: absolute;
                top: 1px;
                left: 0;
            }
        }

        &:checked + label {

            &::before {
                content: "\f00c";
                color: #fff;
                background: $secondary;
            }
        }
    }

    button {
        appearance: none;
        background: transparent;
        padding: 0;
        @include font-size(16);
        font-weight: 700;
        border: none;
        text-decoration: underline;
        margin: 0 0 0 35px;

        @include media-breakpoint-up(sm) {
            margin: 0;
        }
    }

    .btn {
        margin: 30px 0 0;
        width: 100%;

        @include media-breakpoint-up(sm) {
            margin: 0 25px;
            width: auto;
        }
    }
}

.propertySearch-tray {
    min-width: 100%;
    display: none;

    &.on {
        display: block;
    }

    label {
        display: block;
        padding: 0 15px;
        color: $secondary;
        @include font-size(16);
    }

    .dropdownWrapper {
        margin: 0 0 21px;
    }

    .clear {
        font-weight: 700;
        color: $secondary;
        text-decoration: underline;

        appearance: none;
        border: none;
        background: transparent;
    }
}

.propertySearch-toggle {
    @include font-size(12);
    color: $secondary;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    padding: 15px 0;
    margin: 0 0 45px;
    position: relative;
    min-width: 100%;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        left: -1000px;
        right: -1000px;
        border-bottom: 1px solid $gray-dark;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 60px;
        height: 6px;
        background: $primary;
    }

    &.on span,
    &.on button {

        &::after {
            content: "\f077";
        }
    }

    span,
    button {
        appearance: none;
        border: none;
        background: transparent;
        
        &::after {
            content: "\f078";
            display: inline-block;
            font-family: $icon-font;
            margin: 0 0 0 13px;
        }
    }
}