nav#mainNav {

    .container {

        @include media-breakpoint-up(xl) {
            padding: 0;
        }
    }

    .logo {
        
        img {
            display: block;
            height: 30px;
            width: auto;

            @include media-breakpoint-up(md) {
                height: 25px;
            }

            @include media-breakpoint-up(lg) {
                height: 30px;
            }
        }
    }
}

.mainNav-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;

    @include media-breakpoint-up(md) {
        padding: 0;
    }
}

.menuToggle {
    color: $secondary;
    cursor: pointer;
    appearance: none;
    border: none;
    background: none;
    
    @include media-breakpoint-up(md) {
        display: none;
    }

    &:focus {
        border: 1px solid $primary;
    }

    &.on {

        &::after {
            content: "\f00d";
        }
    }

    &:hover {
        color: $primary;
    }

    &::after {
        content: "\f0c9";
        display: block;
        font-family: $icon-font;
        font-weight: 700;
        @include font-size(24);
    }
}

.headerTray {
    flex-wrap: wrap;
    padding: 17px 0 0;
    display: none;
    width: 100%;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
    }
}