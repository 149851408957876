.modal-dialog {
    //border-bottom: 11px solid $primary;
    border-radius: 0;
    @include font-size(16);
    background: #fff;

    @include media-breakpoint-up(lg) {
        @include font-size(20);
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        width: 100%;
        height: 64px;
        background: url(../images/modal-bottom.png) 100% 0 no-repeat;
    }

    .modal-content {
        border-radius: 0;
    }

    button.close {
        appearance: none;
        border: none;
        font-size: 34px;
        line-height: 1;
        cursor: pointer;
    }
}

.modal-actions {
    text-align: center;
}