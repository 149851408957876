section.subBanner {
    padding: 0 0;
    margin: 0 0 70px;
    background-size: cover;
    background-position: 60% 50%;
    background-repeat: no-repeat;
    position: relative;

    @include media-breakpoint-up(lg) {
        height: 435px;
    }

    &.noImage {
        height: 0;
        padding: 0;

        @include media-breakpoint-up(md) {
            margin: 0 0 115px;
        }

        &::after {
        
            @include media-breakpoint-up(md) {
                height: 115px;
            }
        }
    }

    &::after {
        content: "";
        display: block;
        height: 70px;
        background: $primary url(../images/orange-bars-short.jpg) 0 100% repeat-x;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
    }

    .row {

        @include media-breakpoint-up(lg) {
            height: 435px;
        }
    }

    .caption {

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            width: 100%;
        }
    }

    h1,
    .header {
        font-family: $font-family-serif;
        font-weight: normal;
        @include font-size(50);
        color: #fff;
        margin: 0;
        text-shadow: 1px 1px 2px rgba(#000, 0.5);

        @include media-breakpoint-up(md) {
            @include font-size(70);
            text-shadow: 0 0 32px rgba(#000, 0.4);
        }

        @include media-breakpoint-up(lg) {
            @include font-size(90);
        }

        @include media-breakpoint-up(xl) {
            //max-width: 50%;
        }
    }
}