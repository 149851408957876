.topBar {
    display: none;
    
    @include media-breakpoint-up(md) {
        display: block;
        background: $secondary;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
    }
}

.topBar-inner {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include media-breakpoint-up(md) {
        height: 40px;
        min-width: 100%;
    }
}

.topLinks {
    @include font-size(10);
    font-weight: 900;
    text-transform: uppercase;
    display: inline;

    @include media-breakpoint-up(md) {
        @include font-size(12);
        display: flex;
        opacity: 1;
        transition: opacity 0.2s;
    }

    &.off {

        @include media-breakpoint-up(md) {
            opacity: 0;
            pointer-events: none;
        }
    }
    
    ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include media-breakpoint-up(md) {
            justify-content: flex-end;
        }
    }

    li {
        margin-right: 25px;
        padding: 5px 0;

        @include media-breakpoint-up(md) {
            margin-left: 15px;
            margin-right: 20px;
            padding: 0;
        }

        &.social {
            @include font-size(18);
            font-weight: 400;
            margin-right: 15px;

            @include media-breakpoint-up(md) {
                margin-right: 0;
                margin-left: 15px;
            }
            &:first {

                @include media-breakpoint-up(md) {
                    margin-left: 35px;
                }
            }
        }
    }

    a {
        color: $gray-darker;

        @include media-breakpoint-up(md) {
            color: #fff;
        }

        &:hover {
            color: $primary;

            @include media-breakpoint-up(md) {
                color: rgba(#fff, 0.8);
            }
        }
    }
}

.topSearch {
    min-width: 100%;

    @include media-breakpoint-up(md) {
        margin-left: 25px;
        min-width: 1px;
        position: relative;
    }

    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.topSearch-tray {
    margin: 25px 0 14px;
    border: 1px solid $gray-dark;

    @include media-breakpoint-up(md) {
        //display: none;
        background: #fff;
        position: absolute;
        right: 35px;
        top: 0;
        height: 100%;
        margin: 0;
        border: none;
        display: flex;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s;
    }

    @include media-breakpoint-up(lg) {
        position: relative;
        right: auto;
        top: auto;
        width: 0;
        max-width: 0;
        opacity: 1;
        transition: width 0.2s;
        overflow: hidden;
    }

    &.on {

        @include media-breakpoint-up(md) {
            opacity: 1;
            pointer-events: auto;
            min-width: 350px;
        }

        @include media-breakpoint-up(lg) {
            width: 250px;
            min-width: 250px;
            max-width: 100%;
        }

        @include media-breakpoint-up(xl) {
            width: 300px;
            min-width: 300px;
        }
    }

    input[type="text"] {
        appearance: none;
        border: none;
        height: 38px;
        line-height: 38px;
        padding: 0 15px;
        @include font-size(16);
        width: calc(100% - 50px);
        margin: 0;

        @include media-breakpoint-up(md) {
            width: 300px;
        }

        @include media-breakpoint-up(lg) {
            //width: 200px;
        }
    }

    input[type="submit"] {
        appearance: none;
        border: none;
        color: $secondary;
        font-weight: 900;
        @include font-size(18);
        height: 40px;
        padding: 0 9px;
        line-height: 40px;
        width: 50px;
        min-width: 50px;
        text-align: center;
        margin: 0;
        text-transform: uppercase;

        &:hover {
            background: $secondary;
            color: #fff;
        }
    }
}

.topSearch-toggle {
    color: #fff;
    cursor: pointer;
    display: none;
    border: none;
    appearance: none;
    background: transparent;

    @include media-breakpoint-up(md) {
        display: block;
        height: 40px;
        line-height: 40px;
        width: 30px;
        text-align: center;
    }

    @include media-breakpoint-up(lg) {
        width: 40px;
    }

    &:hover {
        color: rgba(#fff, 0.8);
    }

    &::after {
        content: "\f002";
        font-family: $icon-font;
        display: inline-block;
        font-weight: 700;
    }

    &.on {

        &::after {
            content: "\f00d";
        }
    }
}