.contactSearch {
    margin: 0 0 35px;

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 50px 0 0;
        margin: 0 0 50px;
        min-width: 100%;
    }

    .row {
        flex-grow: 1;
    }

    h3 {
        @include font-size(24);
        font-weight: 400;
        margin: 0 0 11px;
        width: 100%;
    }

    .col-sm-6 {
        margin-bottom: 0;
    }

    .searchField {
        border: 1px solid $gray-light;
        display: flex;
        justify-content: space-between;
        margin: 0 0 11px;

        @include media-breakpoint-up(lg) {
            margin: 0 13px 0 0;
        }

        input[type="text"] {
            appearance: none;
            border: none;
            @include font-size(16);
            padding: 0 15px;
            height: 38px;
            line-height: 38px;
            flex-grow: 1;

            @include placeholder {
                color: #333;
            }
        }

        input[type="submit"] {
            text-indent: -9999px;
            appearance: none;
            width: 50px;
            position: relative;
            border: none;
            background: url(../images/icon-search.png) 50% 50% no-repeat;
            background-size: 21px auto;

            &:hover {
                background: $secondary url(../images/icon-search-white.png) 50% 50% no-repeat;
                background-size: 21px auto;
            }

            &:focus {
                border: 1px solid $secondary;
            }
        }
    }

    .dropdownWrapper {
        //border: 1px solid $gray-light;
        margin: 0 0 15px;

        @include media-breakpoint-up(lg) {
            margin: 0 13px 0 0;
        }

        select {
            background: #fff;
            font-weight: normal;
        }
    }

    .clear {
        @include font-size(16);
        font-weight: 700;
        text-decoration: underline;
        color: $secondary;
        cursor: pointer;
        appearance: none;
        border: none;
        background: transparent;

        @include media-breakpoint-up(xl) {
            margin-top: 25px;
        }
        
        &:hover {
            color: $primary;
        }
    }
}

.contactBox-wrapper {

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        margin: 0 0 35px;
    }
}

.contactBox {
    @include font-size(16);
    margin: 0 0 35px;

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: calc(50% - 10px);
    }

    h3 {
        @include font-size(18);
    }

    p {
        margin: 0 0 13px;
    }

    a {
        font-weight: 700;

        &::before {
            content: "";
            display: inline-block;
            font-family: $icon-font;
            margin: 0 13px 0 0;
            font-weight: 400;
            color: $accent1;
        }

        &.phone {

            &::before {
                content: "\f095";
            }
        }

        &.email {

            &::before {
                content: "\f0e0";
            }
        }
    }
}

.contactBox-thumb {
    display: none;

    @include media-breakpoint-up(md) {
        display: block;
        width: 60px;
    }

    @include media-breakpoint-up(lg) {
        width: 100px;
    }

    &::after {
        content: "";
        display: block;
        padding: 0 0 100%;
    }
}

.contactBox-content {

    @include media-breakpoint-up(md) {
        flex-grow: 1;
        padding: 0 0 0 15px;
    }
}

.sidebar-contact {
    @include font-size(16);
    margin: 0 0 35px;

    h3 {
        @include font-size(16);
        color: $gray-darkest;
    }

    p {
        margin: 0 0 5px;
    }
 
    a {
        font-weight: 700;

        &::before {
            content: "";
            display: inline-block;
            font-family: $icon-font;
            margin: 0 13px 0 0;
            font-weight: 400;
            color: $accent1;
        }

        &.phone {

            &::before {
                content: "\f095";
            }
        }

        &.email {

            &::before {
                content: "\f0e0";
            }
        }
    }
}