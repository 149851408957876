.btn {

    &.btn-secondary {

        &:hover {
            background: $accent1;
            color: $secondary;
            border-color: $accent1;
        }
    }
}