.searchResult {
    @include font-size(16);
    color: $gray-darkest;
    margin: 0 0 35px;

    @include media-breakpoint-up(md) {
        margin: 0 0 50px;
        @include font-size(18)
    }

    p + & {
        margin-top: 4rem;
    }

    h2 {
        @include font-size(20);
        font-weight: 400;

        @include media-breakpoint-up(md) {
            @include font-size(24);
            margin: 0 0 15px;
        }
    }
}

.searchResult-action {
    font-weight: 700;
    color: $secondary;
    text-decoration: underline;

    &:hover {
        //color: $primary;
        text-decoration: none;
    }

    &:focus {
        border: 1px solid $secondary;
    }

    &::after {
        content: "\f054";
        display: inline-block;
        font-family: $icon-font;
        margin: 0 0 0 7px;
        @include font-size(12);
    }
}