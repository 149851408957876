.imageGalleryWrapper {
	display: flex;
	flex-wrap: wrap;
}

.imageGallery-item {
	width: calc(33.333333% - 20px);
	margin: 0 10px 20px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;

	&::after {
		content: "";
		display: block;
		padding: 0 0 65%;
	}
}