section.generalBody {
    padding: 31px 0;

    .row > div:not(.col) {
        margin-bottom: 35px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    .ctaBoxWrapper {
        margin-bottom: 3rem;
    }
}

.sidebar {
    position: relative;
    padding-top: 35px;

    @include media-breakpoint-up(md) {
        padding-top: 100px;
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 15px;
        width: 120px;
        height: 10px;
        background: $accent1;

        @include media-breakpoint-up(md) {
            top: 60px;
        }
    }

    h2 {
        @include font-size(24);
        margin: 0 0 35px;
        font-weight: 400;
    }
    
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        @include font-size(16);
        margin: 0 0 21px;

        &.current {
            font-weight: 900;
        }

        a {
            color: $secondary;
        }
    }

    li ul {
        padding: 11px 0 0;
    }

    li li {
        @include font-size(14);
        margin: 0 0 11px;
        padding: 0 0 0 25px;

        a {
            color: $gray-darker;
        }
    }
}

.mainBody {

    @include media-breakpoint-up(lg) {
        padding-right: 50px;
    }
}