.leadership-top {
	padding: 0 0 45px;
	margin: 0 0 45px;
	border-bottom: 1px solid $gray-light;

	@include media-breakpoint-up(sm) {
		display: flex;
		justify-content: space-between;
	}
}

.leadership-title {
	font-style: italic;
	margin-top: -9px;
}

.leadership-top-content {
	padding: 0 35px 0 0;

	@include media-breakpoint-up(md) {
		flex-grow: 1;
	}
}

.leadership-top-content-inner {

	@include media-breakpoint-up(lg) {
		display: flex;
	}
}

.leadership-top-content-left {

	@include media-breakpoint-up(lg) {
		margin: 0 55px 0 0;
	}
}

.leadership-top-content-right {

	.download {
		display: block;

		&::before {
			content: "\f019";
			font-family: $icon-font;
			display: inline-block;
			margin: 0 7px 0 0;
		}
	}
}

.leadership-top-thumb {
	
	@include media-breakpoint-up(sm) {
		max-width: 200px;
	}

	@include media-breakpoint-up(lg) {
		max-width: 225px;
	}

	img {
		display: block;
		width: 250px;
		height: auto;
		margin: 0 auto;

		@include media-breakpoint-up(sm) {
			width: 100%;
		}
	}
}

.leadership-body {

	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: space-between;
	}
}

.leadership-body-content {
	
	@include media-breakpoint-up(md) {
		padding: 0 35px 0 0;
	}
}

.leadership-body-education {

	@include media-breakpoint-up(md) {
		width: 200px;
		min-width: 200px;
		max-width: 200px;
	}

	@include media-breakpoint-up(lg) {
		min-width: 225px;
		max-width: 225px;
	}
}