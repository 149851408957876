section.searchRow {
    background: $primary url(../images/orange-bars-tall.jpg) 0 100% repeat-x;
    padding: 21px 0 30px;

    @include media-breakpoint-up(lg) {
        padding: 33px 0;
        background-image: url(../images/orange-bars-short.jpg);
    }
}

.searchRow-inner {

    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.searchRow-label {
    @include font-size(20);
    color: $secondary;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 17px;

    @include media-breakpoint-up(lg) {
        margin: 0 9px;
    }
}

.searchRow-input {
    margin: 0 0 13px;

    @include media-breakpoint-up(lg) {
        margin: 0 9px;
    }

    &:last-child {
        margin: 0;

        @include media-breakpoint-up(lg) {
            margin: 0 9px;
        }
    }

    input[type="text"] {
        height: 50px;
        line-height: 50px;
        width: calc(100% - 74px);
        margin: 0 8px 0 0;
        @include font-size(16);
        font-weight: 700;
        padding: 0 15px;
        appearance: none;
        border: none;
        vertical-align: top;
        color: $gray-darker;

        @include placeholder {
            color: #333;
        }

        @include media-breakpoint-up(lg) {
            width: 300px;
        }

        @include media-breakpoint-up(xl) {
            width: 300px;
        }
    }

    input[type="submit"] {
        width: 66px;
        height: 50px;
        line-height: 50px;
        background: $secondary;
        color: #fff;
        @include font-size(18);
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        appearance: none;
        border: none;
        vertical-align: top;

        &:hover {
            background: #fff;
            color: $secondary;
        }

        &:focus {
            border: 1px solid $secondary;
        }
    }

    .dropdownWrapper {

        @include media-breakpoint-up(lg) {
            width: 250px;
        }

        @include media-breakpoint-up(xl) {
            width: 330px;
        }
    }
}