section.detailHero {
    position: relative;
    margin: 0 0 70px;

    &::after {
        content: "";
        display: block;
        height: 70px;
        background: $primary url(../images/orange-bars-short.jpg) 0 100% repeat-x;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
    }

    .caption {
        padding-top: 30px;
        padding-bottom: 0;

        @include media-breakpoint-up(md) {
            margin-left: 50%;
        }

        @include media-breakpoint-up(lg) {
            padding: 30px 30px 0 110px;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: -5px;
            left: 15px;
            height: 10px;
            width: 120px;
            background: $accent1;

            @include media-breakpoint-up(md) {
                top: 15px;
                left: -60px;
            }
            
            @include media-breakpoint-up(lg) {
                top: 75px;
            }
        }

        h1 {
            margin: 0 0 15px;

            & + p {
                @include font-size(16);

                @include media-breakpoint-up(lg) {
                    @include font-size(18);
                    margin: 0 0 30px;
                }
            }
        }

        a {
            font-weight: 700;
            display: block;
        }

        a.directions {
            margin: 0 0 5px;

            &::before {
                content: "\f3c5";
                display: inline-block;
                margin: 0 5px 0 0;
                font-family: $icon-font;
            }
        }

        a.longLat {
            margin: 0 0 60px;
        }
    }
}

.detailHero-thumb {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        left: 50%;
        bottom: 0;
        width: 50%;
        max-width: 800px;
        transform: translate(-100%, 0);
    }

    &::after {
        content: "";
        display: block;
        padding: 0 0 60%;
    }
}