footer {
    background: $secondary;
    padding: 45px 0 0;
    color: #fff;

    @include media-breakpoint-up(lg) {
        padding: 60px 0 0;
        margin-top: 8rem;
    }

    .ctaBoxRow + &,
    .halfCta + & {

        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }
    }

    .credit {
        background: #fff;
        border-bottom: 8px solid $primary;
        color: $gray-darkest;
        @include font-size(10);
        font-weight: 700;
        padding: 15px 0;
        line-height: 1.8;

        p {

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            color: $gray-darkest;
        }
    }
}

.footerLogo {
    height: 30px;
    width: auto;
    display: block;
    margin: 0 0 21px;

    @include media-breakpoint-up(md) {
        width: 100%;
        height: auto;
        max-width: 200px;
    }
}

.footerCol {
    margin: 0 0 29px;
    @include font-size(16);

    @include media-breakpoint-up(lg) {
        padding-right: 40px;
        margin: 0 0 50px;
    }

    &:first-child {
        @include font-size(14);
    }

    &.social {

        @include media-breakpoint-up(lg) {
            padding-left: 40px;
        }
        
        li {
            @include font-size(14);
            margin: 0 0 9px;
        }
    }

    h2 {
        @include font-size(24);
        color: #fff;
        margin: 0 0 13px;
    }

    h3 {
        @include font-size(16);
        color: #fff;
        font-weight: 400;
        margin: 0 0 9px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    a {
        color: #fff;
    }

    i.fab {
        color: $accent1;
        @include font-size(20);
        margin-right: 11px;
    }
}

.nyse {
    height: 42px;
    width: auto;
}