ul.mainMenu {
    min-width: 100%;
    display: block;
    margin: 0;
    padding: 0;

    @include media-breakpoint-up(md) {
        min-width: 1px;
    }

    li {
        display: block;
        position: relative;

        @include media-breakpoint-up(md) {
            display: inline-block;
            margin: 0 0 0 10px;
        }

        @include media-breakpoint-up(lg) {
            margin: 0 0 0 20px;
        }
    }

    li a {
        @include font-size(18);
        font-weight: 700;
        display: block;
        padding: 14px 0;
        position: relative;

        @include media-breakpoint-up(md) {
            @include font-size(14);
            padding: 35px 15px;
        }

        @include media-breakpoint-up(lg) {
            @include font-size(18);
        }
    }

    li.hasChildren:hover a {

        &::after {
            content: "";
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 0);
                border-bottom: 13px solid $primary;
                border-left: 13px solid transparent;
                border-right: 13px solid transparent;
            }
        }
    }

    li ul {
        display: none;
        background: $gray-lightest;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 100%;
            left: 50%;
            width: 250px;
            z-index: 1000;
            background: #fff;
            padding: 7px 0 0;
            margin: 0;
            transform: translate(-50%, 0);
            border-top: 8px solid $primary;
            border-bottom: 8px solid $primary;
        }
    }

    li ul li {
        margin: 0 0 7px;
        display: block;
    }

    li.hasChildren:hover ul li a {

        &::after {
            content: "";
            display: none;
        }
    }

    li ul li a {
        padding: 7px 25px 7px 35px;
        font-weight: 400;

        @include media-breakpoint-up(md) {
            @include font-size(16);
            padding: 11px 25px;
            color: $gray-darker;
            font-weight: 400;
        }

        &:hover {

            @include media-breakpoint-up(md) {
                background: $gray-lightest;
                color: $gray-darkest;
                text-decoration: none;
            }
        }
    }
}

.dropToggle {
    position: absolute;
    top: 10px;
    right: 5px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;

    @include media-breakpoint-up(md) {
        display: none;
    }

    &.on {

        &::after {
            content: "\f077";
        }
    }

    &::after {
        content: "\f054";
        display: block;
        font-family: $icon-font;
        @include font-size(18);
        font-weight: 700;
    }
}