.pagination {
    display: flex;

    a {
        display: block;
        height: 30px;
        width: 30px;
        line-height: 30px;
        text-align: center;
        background: $gray-lightest;
        color: $gray-darkest;
        margin: 0 3px 3px 0;
        font-weight: 700;
        @include font-size(14);
        padding: 0 5px;

        &:hover {
            background: $primary;
            color: $secondary;
            text-decoration: none;
        }

        &:first-child,
        &:last-child {
            width: auto;
            padding: 0 13px;
        }
    }
}