section.logoRow {
    padding: 25px 0;
}

.logoBox-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 35px 0 0;
}

a.logoBox {
    display: block;
    @include font-size(16);
    text-align: center;
    color: $gray-darkest;
    margin: 0 0 35px;

    @include media-breakpoint-up(lg) {
        padding: 0 25px;
    }
}

.logoBox-logo {
    position: relative;
    margin: 0 0 13px;

    &::after {
        content: "";
        display: block;
        padding: 0 0 50%;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }
}

.logoBox-content {

    h3,
    .title {
        text-transform: uppercase;
        font-weight: 700;
        color: $gray-darkest;
        margin: 0 0 4px;
        @include font-size(16);
    }
}