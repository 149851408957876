html {
    font-size: 62.5%;
}

body {
    @include font-size(18);
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
    margin: 0 0 0.8rem;
    color: $secondary;
    line-height: 1.2;
    @include font-size(28);
    font-weight: 900;

    @include media-breakpoint-up(md) {
        @include font-size(34);
    }

    @include media-breakpoint-up(lg) {
        @include font-size(42);
    }

    &.sm {
        @include font-size(16);

        @include media-breakpoint-up(md) {
            @include font-size(22);
        }
    
        @include media-breakpoint-up(lg) {
            @include font-size(30);
        }

    }

    &.lg {
        @include font-size(32);

        @include media-breakpoint-up(md) {
            @include font-size(38);
        }
    
        @include media-breakpoint-up(lg) {
            @include font-size(46);
        }

    }

    &.xl {
        @include font-size(36);

        @include media-breakpoint-up(md) {
            @include font-size(42);
        }
    
        @include media-breakpoint-up(lg) {
            @include font-size(50);
        }

    }
}

h2 {
    margin: 0 0 0.8rem;
    color: $secondary;
    line-height: 1.2;
    @include font-size(22);
    font-weight: 900;

    @include media-breakpoint-up(md) {
        @include font-size(28);
        margin: 0 0 2rem;
    }

    @include media-breakpoint-up(lg) {
        @include font-size(34);
    }

    &.sm {
        @include font-size(16);

        @include media-breakpoint-up(md) {
            @include font-size(22);
        }
    
        @include media-breakpoint-up(lg) {
            @include font-size(28);
        }

    }

    &.lg {
        @include font-size(26);

        @include media-breakpoint-up(md) {
            @include font-size(32);
        }
    
        @include media-breakpoint-up(lg) {
            @include font-size(38);
        }

    }

    &.xl {
        @include font-size(30);

        @include media-breakpoint-up(md) {
            @include font-size(36);
        }
    
        @include media-breakpoint-up(lg) {
            @include font-size(42);
        }

    }
}

h3 {
    margin: 0 0 0.8rem;
    color: $gray-darkest;
    line-height: 1.2;
    @include font-size(18);
    font-weight: 700;
    color: $secondary;

    @include media-breakpoint-up(md) {
        @include font-size(20);
    }

    &.sm {
        @include font-size(14);

        @include media-breakpoint-up(md) {
            @include font-size(16);
        }

    }

    &.lg {
        @include font-size(20);

        @include media-breakpoint-up(md) {
            @include font-size(24);
        }

    }

    &.xl {
        @include font-size(24);

        @include media-breakpoint-up(md) {
            @include font-size(28);
        }

    }
}

h4 {
    margin: 0 0 0.8rem;
    color: $secondary;
    line-height: 1.2;
    @include font-size(14);

    &.sm {
        @include font-size(12);
    }

    &.lg {
        @include font-size(20);
    }

    &.xl {
        @include font-size(24);
    }
}

h5 {
    margin: 0 0 0.8rem;
    line-height: 1.2;
    @include font-size(14);

    &.sm {
        @include font-size(12);
    }

    &.lg {
        @include font-size(20);
    }

    &.xl {
        @include font-size(24);
    }
}

p + h1,
p + h2,
p + h3,
p + h4 {
    margin-top: 3.6rem;
}

p {
    margin: 0 0 2rem;
}

.scriptHeader {
    font-family: $font-family-serif;
    @include font-size(50);
    font-weight: normal;
    line-height: 0.9;
    color: $secondary;
    margin: 0 0 1rem;
}

.lead {
    font-weight: 700;
}

hr.largeRule {
    width: 100%;
    border: none;
    height: 40px;
    background: $secondary url(../images/orange-bars-short.jpg) 0 100% repeat-x;
    margin: 15px 0;

    .fullCta + & {
        margin-top: 0;
    }
}

.mouse {
    @include font-size(12);
}

.phone-icon {
    position: relative;
    display: inline-block;

    &::before {
        content: "\f095";
        display: inline-block;
        font-family: $icon-font;
        margin: 0 13px 0 0;
        font-weight: 400;
        color: $accent1;
    }
}

.email-icon {
    position: relative;
    display: inline-block;

    &::before {
        content: "\f0e0";
        display: inline-block;
        font-family: $icon-font;
        margin: 0 13px 0 0;
        font-weight: 400;
        color: $accent1;
    }
}