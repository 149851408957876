section.whatMatters {
    background: $primary url(../images/orange-bars-too-tall.jpg) 0 100% repeat-x;
    padding: 35px 0 50px;
    color: $secondary;

    @include media-breakpoint-up(md) {
        text-align: center;
    }

    @include media-breakpoint-up(xl) {
        padding: 95px 0 110px;
    }

    .scriptHeader {
        text-align: center;
        margin: 0 0 30px;

        @include media-breakpoint-up(lg) {
            @include font-size(75);
        }

        @include media-breakpoint-up(xl) {
            @include font-size(90);
        }
    }

    .whatMatters-icon {
        display: inline-block;
        vertical-align: middle;
        width: 58px;
        height: auto;
        margin: 0 25px 0 0;
        padding: 13px 0;

        @include media-breakpoint-up(md) {
            height: 65px;
            width: auto;
            margin: 0;
        }

        @include media-breakpoint-up(lg) {
            height: 93px;
            padding: 20px 0;
        }

        @include media-breakpoint-up(xl) {
            height: 105px;
        }
    }

    h3:not(.scriptHeader),
    .title:not(.scriptHeader) {
        display: inline-block;
        vertical-align: middle;
        color: $secondary;
        @include font-size(20);
        font-weight: 700;

        @include media-breakpoint-up(md) {
            display: block;
        }

        @include media-breakpoint-up(xl) {
            @include font-size(24);
        }
    }

    p {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
            @include font-size(16);
        }

        @include media-breakpoint-up(xl) {
            @include font-size(18);
        }
    }
}