section.ctaBoxRow {
    padding: 45px 0 80px;
    text-align: center;
    @include font-size(16);

    @include media-breakpoint-up(md) {
        padding: 70px 0;
    }

    @include media-breakpoint-up(lg) {
        padding: 90px 0;
        @include font-size(18);
    }

    &.short {
        padding: 50px 0 20px;
    }

    &.gray {
        background-color: $gray-lightest;
        padding-top: 5px;
        padding-bottom: 1px;
    }

    .alt {

        h3 {
            text-align: left;
        }
    }

    h3 {
        @include font-size(24);
        color: $secondary;
        font-weight: 900;
        margin: 0 0 25px;

        @include media-breakpoint-up(md) {
            @include font-size(29);
        }
        
        @include media-breakpoint-up(lg) {
            @include font-size(34);
        }
    }
}

.ctaBoxWrapper {

    .ctaBoxRow & {
        padding-top: 35px;
        margin: 0 0 35px;
    }

    .col,
    .col-12 {
        margin-bottom: 21px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
}

a.ctaBox {
    text-align: left;
    box-shadow: 0 0 6px rgba(#000, 0.2);
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;
    margin-bottom: 35px;

    .alt & {
        flex-direction: row;
        margin: 0 0 25px;
    }

    &:hover {
        text-decoration: none;

        h3,
        .title {
            text-decoration: underline;
        }
    }

    h3,
    .title {
        @include font-size(18);
        margin: 0;
        color: $secondary;
        font-weight: bold;

        @include media-breakpoint-up(md) {
            margin: 0 0 11px;
        }
    }
}

.ctaBox-thumb {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    
    .alt & {
        display: none;

        @include media-breakpoint-up(lg) {
            min-width: 140px;
            display: block;
        }
    }

    &::after {
        content: "";
        display: block;
        padding: 0 0 65%;
    }
}

.ctaBox-content {
    position: relative;
    padding: 15px 30px 30px;
    @include font-size(16);
    color: $gray-darkest;
    line-height: 1.4;
    flex-grow: 1;

    .alt & {
        padding: 30px;

        @include media-breakpoint-up(lg) {
            padding: 30px 30px 30px 20px;
        }

        &::after {
            content: "";
            display: block;
            border-top: 1px solid $primary;
            top: 9px;

            @include media-breakpoint-up(lg) {
                left: 0;
                border-left: none;
            }
        }
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 9px;
        bottom: 9px;
        right: 9px;
        border: 1px solid $primary;
        border-top: none;
    }

    p {
        //display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }

        &:last-child {
            margin: 0;
        }
    }
}

.ctaBox-actions {
    padding: 35px 0 0;
    text-align: left;
}