$font-family-sans-serif:            'Lato', sans-serif;
$font-family-serif:                 'LS Hymned';

$primary:							#F58221;
$primary-dark:						darken($primary, 10%);
$secondary:							#1C144D;
$secondary-dark:						darken($secondary, 10%);
$accent1:							#6EBFFF;
$accent1-dark:						darken($accent1, 10%);
$accent2:							#ED1C24;
$accent2-dark:						darken($accent2, 10%);
$accent3:							#FFB514;
$accent3-dark:						darken($accent3, 10%);

$alert-danger:						#A82C0E;
$alert-success:						#009D3A;

$gray-lightest:						#EEEDEB;
$gray-lighter:						#E7E7E8;
$gray-light:						#D7D4CC;
$gray:								#98968F;
$gray-dark:							#77787B;
$gray-darker:						#63615D;
$gray-darkest:						#434343;

$link-color:						$secondary;
$link-hover-color:					$secondary-dark;

$input-btn-padding-y:         		.575rem;
$input-btn-padding-x:         		1.5rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1300px
);

.container-fluid {
	max-width: 1300px;
	padding: 0 25px;
}

// Modals

// Padding applied to the modal body
$modal-inner-padding:               11px 35px;
$modal-content-border-width:        0;
