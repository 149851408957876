section.detailTabs {
    //background: $secondary;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        overflow: visible;
        background: $secondary;
    }

    .container,
    .container-fluid {
        padding: 0;

        @include media-breakpoint-up(md) {
            @include make-container();
            
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        justify-content: space-between;
        justify-content: flex-start;

        @include media-breakpoint-up(md) {
            //justify-content: center;
            margin-left: -15px;
        }

        @include media-breakpoint-up(xl) {
            @include make-col-offset(1);
        }

        li {
            width: calc(50% - 1px);
            color: #fff;
            text-align: center;
            @include font-size(16);
            border-bottom: 3px solid #fff;
            position: relative;
            border-right: 1px solid #fff;
            cursor: pointer;
            background: $secondary;

            @include media-breakpoint-up(md) {
                width: auto;
                border: none;
                //padding: 21px 11px;
                background: transparent;
            }

            @include media-breakpoint-up(lg) {
                padding: 0 20px;
            }

            @include media-breakpoint-up(xl) {
                padding: 0 30px;
            }

            &:first-child {

                @include media-breakpoint-up(xl) {
                    margin-left: -30px;
                }
            }

            &.on {
                background: $accent1;
                color: $secondary;

                @include media-breakpoint-up(md) {
                    background: transparent;
                    color: #fff;
                }
                
                &::before {
                    background: $accent1;

                    @include media-breakpoint-up(md) {
                        top: auto;
                        bottom: -5px;
                        left: 0;
                        right: 0;
                        height: 10px;
                        width: 100%;
                    }

                    @include media-breakpoint-up(lg) {
                        left: 20px;
                        right: 20px;
                        width: calc(100% - 40px);
                    }

                    @include media-breakpoint-up(xl) {
                        left: 30px;
                        right: 30px;
                        width: calc(100% - 60px);
                    }
                }

                a {
                    color: $secondary;

                    @include media-breakpoint-up(md) {
                        color: #fff;
                    }
                }
            }

            &:nth-child(even) {
                border-right: none;
                border-left: 2px soild #fff;

                @include media-breakpoint-up(md) {
                    border: none;
                }
                
                &.on {

                    &::before {
                        left: auto;
                        right: -2px;

                        @include media-breakpoint-up(lg) {
                            left: 20px;
                            right: 20px;
                            width: calc(100% - 40px);
                        }
    
                        @include media-breakpoint-up(xl) {
                            left: 30px;
                            right: 30px;
                            width: calc(100% - 60px);
                        }
                    }
                }
            }

            &:hover {
                background: $accent1;
                color: $secondary;
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                height: 200px;
                width: 5px;
                background: transparent;
                z-index: 10;
            }

            a {
                color: #fff;
                display: block;
                padding: 9px;

                @include media-breakpoint-up(md) {
                    padding: 21px 11px;
                }
    
                @include media-breakpoint-up(lg) {
                    padding: 25px 0;
                }
    
                @include media-breakpoint-up(xl) {
                    padding: 25px 0;
                }
            }
        }
    }
}

section.detailTabContentWrapper {

}

.detailTabContent {
    display: none;
    border-left: 5px solid $accent1;
    padding-top: 25px;
    padding-bottom: 25px;

    @include media-breakpoint-up(md) {
        border: none;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    &:nth-child(even) {
        border-left: none;
        border-right: 5px solid $accent1;

        @include media-breakpoint-up(md) {
            border: none;
        }
    }

    &.on {
        display: block;
    }
}

.detailTable {
    margin: 0 0 50px;
}

.detailTable-inner {
    background: $gray-lightest;

    h3 {
        background: $gray-lighter;
        padding: 13px 21px;
        @include font-size(18);
        color: $gray-darkest;
        text-transform: uppercase;
    }

    .content {
        padding: 13px 21px;
        @include font-size(16);
    }

    p {
        margin: 0 0 11px;
    }

    table {
        
    }

    th {
        padding: 9px 5px;
        border-bottom: 1px solid $gray-light;
    }

    td {
        padding: 9px 5px;
    }
}

.detailTabContent-mapEmbed {
    margin: 0 0 45px;
}

.detailTabContent-planBox {
    margin-bottom: 35px;
    @include font-size(16);
    font-weight: 700;

    h3 {
        border-bottom: 1px solid $gray-light;
        padding-bottom: 9px;
    }

    td {
        padding: 5px 7px;
    }
}

.detailTabContent-platWrapper,
.detailTabContent-mapWrapper {
    background: $gray-lightest;
    position: relative;

    &::after {
        content: "";
        display: block;
        padding: 0 0 65%;
    }
}

ul.detailTabContent-mapSelect {
    list-style: none;
    padding: 8px 0;
    margin: 0;
    @include font-size(10);
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(sm) {
        justify-content: flex-end;
    }

    @include media-breakpoint-up(md) {
        margin: -25px 0 25px;
    }

    li {
        margin: 0 5px;

        @include media-breakpoint-up(md) {
            margin: 0 0 0 31px;
        }

        &::before {
            content: "";
            display: inline-block;
            font-family: $icon-font;
            margin: 0 7px 0 0;
        }

        &.capsule {

            &::before {
                content: "\f279";
            }
        }

        &.google {

            &::before {
                content: "\f1a0";
                font-family: "Font Awesome 5 Brands"
            }
        }

        &.street {

            &::before {
                content: "\f3c5";
            }
        }
    }
}