section.homeHero {
    background: #fff;
    position: relative;
    padding: 250px 0;
    overflow: hidden;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 400px;

    @include media-breakpoint-up(lg) {
        height: 550px;
    }

    .mobileX {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 860px;
        width: auto;
        max-width: 2000px;
        opacity: 0;
        animation: mobileX 1s ease-out forwards;

        @include media-breakpoint-up(md) {
            display: none;
        }

        @keyframes mobileX {
            to {
                opacity: 1;
            }
        }
    }

    .edgeX {
        display: none;
    }

    @supports (-ms-ime-align:auto) {
        svg.desktop {
            display: none;
        }
        
        .edgeX {
            display: none;
            
            @include media-breakpoint-up(md) {
                display: block;
                position: absolute;
                top: -10px;
                left: 0;
                height: calc(100% + 20px);
                width: auto;
                opacity: 0;
                animation: edge-fade 1.4s cubic-bezier(0.77, 0, 0.175, 1) .5s forwards;
            }
    
            @keyframes edge-fade {
                to {
                    opacity: 1;
                }
            }
        }
    }

    svg.desktop {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: calc(100% + 20px);
        display: none;
        max-width: 1010px;

        @include media-breakpoint-up(md) {
            display: block;
        }

        @include media-breakpoint-up(lg) {
            top: -10px;
            left: 0;
            transform: translate(0, 0);
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        svg.desktop {
            display: none;
        }

        .edgeX {
            display: none;
            
            @include media-breakpoint-up(md) {
                display: block;
                position: absolute;
                top: -10px;
                left: 0;
                height: calc(100% + 20px);
                width: auto;
                opacity: 1;
            }
        }
    }

    #Stroke-1 {
        stroke-dasharray: 750px;
        stroke-dashoffset: 750px;
        animation: dash-1 1.4s cubic-bezier(0.77, 0, 0.175, 1) .5s forwards;
        stroke-width: 10px;
        opacity: 0;
    }

    *::-ms-backdrop, #Stroke-1 {
        stroke-dashoffset: 0px;
        animation: none;
    }

    @keyframes dash-1 {
        to {
            stroke-dashoffset: 0px;
            stroke-width: 10px;
            opacity: 1;
        }
    }

    #Stroke-3 {
        stroke-dasharray: 132px;
        stroke-dashoffset: 132px;
        animation: dash-3 1s cubic-bezier(0.77, 0, 0.175, 1) 1.25s forwards;
        stroke-width: 10px;
        opacity: 0;
    }

    *::-ms-backdrop, #Stroke-3 {
        stroke-dashoffset: 0px;
        animation: none;
    }

    @keyframes dash-3 {
        to {
            stroke-dashoffset: 0px;
            stroke-width: 10px;
            opacity: 1;
        }
    }

    #Stroke-2 {
        stroke-dasharray: 760px;
        stroke-dashoffset: 760px;
        animation: dash-2 1.4s cubic-bezier(0.77, 0, 0.175, 1) .5s forwards;
        stroke-width: 10px;
        opacity: 0;
    }

    *::-ms-backdrop, #Stroke-2 {
        stroke-dashoffset: 0px;
        animation: none;
    }

    @keyframes dash-2 {
        to {
            stroke-dashoffset: 0px;
            stroke-width: 10px;
            opacity: 1;
        }
    }

    #Stroke-4 {
        stroke-dasharray: 139px;
        stroke-dashoffset: 139px;
        animation: dash-4 1s cubic-bezier(0.77, 0, 0.175, 1) 1.25s forwards;
        stroke-width: 10px;
        opacity: 0;
    }

    *::-ms-backdrop, #Stroke-4 {
        stroke-dashoffset: 0px;
        animation: none;
    }

    @keyframes dash-4 {
        to {
            stroke-dashoffset: 0px;
            stroke-width: 10px;
            opacity: 1;
        }
    }

    #Path-1 {
        fill: transparent;
        opacity: 0.34;
        //animation: path-1 2s ease-out 3.3s forwards;
    }

    @keyframes path-1 {
        to {
            fill: #000;
        }
    }
}

.homeHero-caption {
    position: absolute;
    text-align: center;
    color: #fff;
    @include font-size(16);
    font-weight: 700;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: homeHero-caption 0.5s ease-in .5s forwards;
    width: 280px;

    @include media-breakpoint-up(md) {
        width: 350px;
        padding: 0;
        animation: homeHero-caption 0.5s ease-in 1.5s forwards;
    }

    @include media-breakpoint-up(lg) {
        left: 261px;
        transform: translate(0, -50%);
        @include font-size(24);
        width: 470px;
        font-weight: 400;
    }

    h1 {
        color: #fff;
        font-family: $font-family-serif;
        font-weight: 400;
        @include font-size(50);
        margin: 0 0 25px;

        @include media-breakpoint-up(lg) {
            @include font-size(90);
        }
    }

    p {

        @include media-breakpoint-up(lg) {
            padding: 0 50px;
        }
    }
}

@keyframes homeHero-caption {
    to {
        opacity: 1;
    }
}