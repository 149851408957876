section.detailDownloads {
    padding: 30px 0;
    
    &.bg-gray {
        background: $gray-lightest;
    }

    & + .largeRule {
        margin-top: 0;
    }
}