.resourceBox-wrapper {
    padding: 35px 0 0;

    h2 {

        @include media-breakpoint-up(sm) {
            @include font-size(18);
        }

        @include media-breakpoint-up(lg) {
            @include font-size(22);
        }
    }
}

.resourceBox {
    @include font-size(16);
    margin-bottom: 35px !important;

    h3 {
        color: $gray-darkest;
        @include font-size(16);
        margin: 0 0 5px;
        text-transform: uppercase;
    }

    p {
        margin: 0 0 15px;
    }

    a {
        display: block;
        margin: 0 19px 0 0;
        font-weight: 700;

        &.email {
            display: inline-block;

            &::before {
                content: "\f0e0";
            }
        }

        &.phone {
            display: inline-block;
            
            &::before {
                content: "\f095";
            }
        }

        &::before {
            content: "";
            display: inline-block;
            font-family: $icon-font;
            font-weight: 300;
            margin: 0 9px 0 0;
            color: $accent1;
        }
    }
}