section.iconCtaRow {
    padding: 40px 0;
    @include font-size(16);

    @include media-breakpoint-up(md) {
        @include font-size(18)
    }

    h2 {
        @include font-size(21);

        @include media-breakpoint-up(md) {
            @include font-size(34);
            margin: 0 0 31px;
        }
    }
}

.iconCtaWrapper {
    padding: 25px 0 0;

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

a.iconCta {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include font-size(16);
    font-weight: 700;
    border-right: 10px solid $primary;
    box-shadow: 0 0 11px rgba(#000, 0.2);
    margin: 0 0 21px;
    @include font-size(16);
    padding: 13px 15px;
    min-height: 75px;
    background: #fff;

    @include media-breakpoint-up(sm) {
        //width: calc(50% - 8px);
    }

    @include media-breakpoint-up(md) {
        //max-width: calc(33.333333% - 8px);
        margin: 0 0 15px;
    }

    .icon {
        width: 40px;
        min-width: 40px;
        height: auto;
    }

    h3,
    .title {
        padding: 0 0 0 21px;
        margin: 0;
        @include font-size(16);
    }
}